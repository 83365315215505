






















































import { defineComponent } from "@vue/composition-api";
import { mapActions as mapPiniaActions, mapState as mapPiniaState } from "pinia";
import type { IomodelsCoachappLidStatusVoorSlug } from "@/lib/backend/club.api";
import { useGebruikerStore } from "@/pinia/gebruiker";
import { useLocationStore } from "@/pinia/location";
import { getMemberImage } from "@/utils/images";
import { missingDataMixin } from "./missingDataMixin";

type Data = {
	links: Link[];
};

type Link = {
	to: string;
	label: string;
	slug?: string;
	icon: string;
	activeIcon?: string;
};

export default defineComponent({
	mixins: [missingDataMixin],
	data(): Data {
		return {
			links: [
				{
					to: "Gebruiker",
					label: "Dashboard",
					icon: "profile-image",
				},
				{
					to: "Gebruiker persoonlijk gegevens",
					label: "Gegevens",
					slug: "persoonlijk",
					icon: "member",
				},
				{
					to: "Gebruiker metingen",
					label: "Meting",
					slug: "metingen",
					icon: "metingen",
				},
				{
					to: "Gebruiker doel",
					slug: "doel",
					label: "Doel",
					icon: "doel",
				},
				{
					to: "Gebruiker schema",
					label: "Schema",
					slug: "schemas",
					icon: "schemas",
				},
				{
					to: "Gebruiker groepslessen",
					label: "Groepslessen",
					slug: "groepslessen",
					icon: "live",
				},
				{
					to: "Gebruiker activiteiten",
					label: "Activiteiten",
					icon: "activiteit_outline",
					activeIcon: "activiteit_solid",
				},
				{
					to: "Gebruiker berichten",
					label: "Berichten",
					icon: "intensiteit_outline",
					activeIcon: "intensiteit_solid",
				},
			],
		};
	},
	computed: {
		...mapPiniaState(useLocationStore, { location: "location" }),
		...mapPiniaState(useGebruikerStore, {
			id: "id",
			lidInfo: "lidInfo",
			doelFrequentie: "doelFrequentie",
			medical: "medical",
			sections: ({ basicInfo }): IomodelsCoachappLidStatusVoorSlug[] => basicInfo?.sections ?? [],
			gebruikerImage: ({ id, basicInfo }): string | undefined => {
				if (!id) return;
				if (!basicInfo) return getMemberImage({ id });

				return getMemberImage({
					id,
					profileImg: basicInfo.profileImg,
				});
			},
			isCoachGroup30: ({ lidInfo }) => lidInfo?.coachingsGroep === "30%",
		}),
		showGebruikerView(): boolean {
			return this.$route.matched.at(-1)?.meta.gebruikerView ?? true;
		},
	},

	watch: {
		$route: "check",
	},

	async mounted() {
		await this.check();
	},

	methods: {
		...mapPiniaActions(useGebruikerStore, ["resetState", "set", "getBasicInfo", "getMedical"]),

		async check() {
			const { id } = this.$route.params;

			const numberId = Number(id);

			if (isNaN(numberId)) return;

			const currentId = this.id;

			if (currentId !== numberId) this.set(Number(id));

			await this.getRequiredInfo();
		},
		async getRequiredInfo() {
			await this.getBasicInfo();
			await this.getMedical();
		},
		getStatus(slug: string): IomodelsCoachappLidStatusVoorSlug["status"] | undefined {
			const section = this.sections.find(({ slug: sectionSlug }) => sectionSlug === slug);

			if (!section) return;
			return section.status;
		},
	},
});
